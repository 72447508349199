@import 'theme/variables.scss';

.newsboard-live-channel-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;

  .channel-controls-wrapper {
    // background-color: $color-light-grey;
    // padding: 5px 5px 0px 5px;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    .window-close-icon {
      font-size: 20px;
      top: -10px;
      right: -10px;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      svg {
        path:nth-of-type(1) {
          fill: #ef233c;
        }
        path:nth-of-type(2) {
          fill: #3e404b;
        }
        path:nth-of-type(3) {
          fill: #ef233c;
        }
      }
    }
    .channel-name {
      position: absolute;
      top: 8px;
      left: 10px;
    }

    .actus-player-wrapper {
      width: 100%;
      aspect-ratio: 16/9;
      position: relative;
      display: flex;
      .fullscreen {
        flex: 1;
      }
      .no-live-media {
        display: flex;
        position: absolute;
        top: 50%;
        width: 100%;
        justify-content: center;
        color: $color-white;
        font-size: 1rem;
      }
    }
  }
}
