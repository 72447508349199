@import '../../../../theme/mixins.scss';

.card-wrapper {
  height: 100%;
  padding-top: 10px;

  .ant-card,
  .card-container-primary,
  .medium,
  .square {
    height: 100%;
  }

  .overflow {
    .ant-card-body {
      height: calc(100% - 48px);
      overflow-y: auto;
    }

    .ant-card-extra {
      margin-right: 10px;
      padding-top: 20px;
      padding-bottom: 0;
    }
  }

  .segment-analysis-wrapper-clipper {
    height: 100%;

    .tooltip-span {
      display: none;
      background-color: white;
      color: black;
      padding: 5px;
    }

    &:hover .tooltip-span {
      display: block;
      position: fixed;
      overflow: hidden;
    }
  }
}

.program-info-body-wrapper {
  display: flex;
  flex-direction: column;
  // max-height: 572px;
  min-height: 420px;
  overflow: auto;

  .ant-select-selection-item {
    background: #72747e !important;
    border-color: #72747e !important;
    color: #f2f2f2;
  }

  .card-container-secondary {
    margin-top: 15px;
  }
}

.program-infomation-container {
  .program-infomation-wrapper {
    // min-height: 36.5vh;
    max-height: 400px;
    min-height: 380px;

    .time-fields {
      display: flex;

      .time-from {
        margin-right: 10px;

        // width: 50%;
        .label {
          display: block;
        }
      }
    }

    .ant-picker .text-primary .small-font-size .margin-left {
      width: 100%;
    }

    .program-info {
      display: flex;
      flex-direction: column;
      padding: 16px 10px;

      .program-label {
        // font-family: $text-font;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.4px;
        color: grey;
        padding-bottom: 5px;

        span {
          font-size: 14px;
          letter-spacing: 0.4px;
          font-weight: 400;
        }
      }
    }

    .program-info-body-wrapper {
      .wrapper-anchors {
        display: flex;

        .warapper {
          width: 100%;
        }
      }

      .label {
        color: #d3d3d3;
      }

      .program-info-btn {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
    }

    .priority-detail {
      display: flex;

      .format-detail {
        width: 100%;
      }
    }

    .program-info-body-wrapper>div:first-child {
      margin-top: 0;
    }

    .input-margin {
      margin-top: 10px;
    }

    .ant-select-selection-item {
      background-color: #3e404b !important;
    }

    .save-btn {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }

  .add-program {
    position: absolute;
    right: 13px;
    top: 12px;
    display: flex;

    // flex-direction: column;
    // align-items: flex-end;
    button {

      // font-size: 11px !important;


    }

    .ims-button-wrapper:nth-child(1) {
      margin-right: 8px;
      // background-color: rgba(72, 190, 235, 1);
      // color: white !important;
      // border-radius: 2px;
      // border: 1px solid rgba(72, 190, 235, 1);
      // font-size: 12px;
      // line-height: 16px;
      // font-family: 'Roboto';
      // text-transform: uppercase;
      // font-weight: bold;
      // letter-spacing: 0.4px;
      // width: 150px;
    }

    button:hover {
      opacity: 1;
    }

    // button[disabled]:hover {
    //   background-color: rgba(72, 190, 235, 1);
    //   color: white !important;
    //   opacity: 0.4;
    //   border: 1px solid rgba(72, 190, 235, 1);
    // }
  }

  .card-container-primary .ant-card-head {
    text-align: left;
    margin-left: 18px;
  }

  .card-container-primary .ant-card-body {
    padding: 16px 18px;
  }

  .align-center {
    align-items: center;
  }

  // .warapper div div div div div{
  //   display: contents;
  // }
  .ant-form-item-control-input-content {
    display: contents;
  }
}