@import '../../../theme/variables.scss';

.awareness-channel-window-container {
  // min-height: 480px;
  // height: 480px;
  min-height: 250px;
  // max-height: 480px;
  display: flex;
  position: relative;
  background-color: $color-light-grey;
  div {
    h1 {
      color: white;
      font-size: x-large;
    }
  }
  .window-close-icon {
    font-size: 20px;
    top: -10px;
    right: -10px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    svg {
      path:nth-of-type(1) {
        fill: #EF233C;
      }
      path:nth-of-type(2) {
        fill: #3e404b;
      }
      path:nth-of-type(3) {
        fill: #EF233C;
      }
    }
  }
  .player-container {
    position: absolute;
    top: 0px;
    left: 0px;
    // min-height: 230px;

    margin: auto auto;
    width: 100%;
    .no-live-media {
      display: flex;
      position: absolute;
      top: 50%;
      width: 100%;
      justify-content: center;
    }
  }
  .fullscreen {
    position: absolute;
    top: 0px;
    left: 0px;
    // min-height: 230px;
    height: 100%; // 62%
    max-height: 100%;
    margin: auto auto;
    width: 100%;
  }

  video {
    object-fit: fill;
  }
}
