@import 'theme/variables';

.hashtag-wrapper {
  height: 100%;
  background: #2A324A;
  padding: 10px;
  .hashtag-header {
    color: $white;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    // margin: 15px 0;
    text-align: center;
  }
  .hashtag-container {
    padding: 20x;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
