.screen-shot-wrapper {
  display: flex;
  // gap: 1rem;
  flex-wrap: wrap;
  & > * {
    flex-basis: 33%;
  }
  position: relative;
  .channel-logo {
    position: absolute;
    top: -5000px;
    visibility: none;
  }
  .image-container {
    display: flex;
    align-items: center;
    // aspect-ratio: 16/9;
    img {
      width: 100%;
      object-fit: fill;
      //   aspect-ratio: 16/9;
    }

    .image-remove-icon {
      font-size: 1.3rem;
      svg {
        path:nth-of-type(1) {
          fill: transparent;
        }
        path:nth-of-type(2) {
          fill: #3e404b;
        }
        path:nth-of-type(3) {
          fill: white;
        }
      }
    }
  }
}
