@import 'theme/variables';

.resetpassword-page-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $secondary-background;
  .resetpassword-wrapper {
    width: 582.57px;
    height: 480px;
    padding: 50px;
    background-color: $color-primary-dark;
    .title {
      width: 100%;
      text-align: center;
      span {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.7px;
        color: $white;
      }
    }

    .form-input-field {
      height: 60px;
      color: $white;
      border: none;
      margin-top: 30px;
      padding: 15px;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 1.1px;
      background-color: $secondary-background !important;
    }
    .resetpassword-button-div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .resetpassword-form-button {
        margin-top: 30px;
        width: 100%;
        height: 50px;
        color: $body-background;
        font-weight: bold;
        background-color: $color-blue;
        letter-spacing: 0.6px;
        box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.15);
        margin-top: 30px;
      }
    }
  }
}
