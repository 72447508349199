@import 'theme/variables';
.forgetpass-page-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .OTP-btn {
    margin-bottom: 20px;
  }
  .OTP-inner {
    width: 52px !important;
    height: 52.9px !important;
    background: rgba(239, 35, 60, 0.11);
    border: 1px solid #9e1d40;
    border-radius: 4px;
    font-size: 1rem;
    .OTP-inner:focus {
      border: 1px solid #9e1d40;
    }
  }
  input:focus {
    border: 1px solid #9e1d40;
  }
  .forgetpass-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 582.57px;
    height: 500px;
    padding: 80px;
    .title {
      width: 100%;
      text-align: center;
      span {
        font-size: 30px;
        font-weight: 800;
        letter-spacing: 0.7px;
        color: $white;
      }
    }
    .text {
      width: 75%;
      text-align: center;
      color: #d3d3d3;
      span {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.7px;
        line-height: 16px;
      }
    }
    .form-input-field {
      background: rgba(61, 1, 27, 0.15);
      border: 1px solid rgba(239, 35, 60, 0.43);
      border-radius: 4px;
      width: 556px !important;
      height: 60.9px !important;
      background: rgba(61, 1, 27, 0.15) !important;
      border: 1px solid #9e1d40 !important;
      border-radius: 4px;

      font-weight: 300;
      font-size: 16px;
      line-height: 19px;

      color: #ffffff;

      opacity: 0.5;
      .ant-input .form-input-field:focus {
        background: rgba(239, 35, 60, 0.05);
        border: 1px solid #ef233c;
        border-radius: 4px;
      }
    }

    .forgetpass-forget-password {
      color: $white;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }

    .forgetpass-button-div {
      .forgetpass-form-button {
        width: 160px;
        height: 42px;
        color: white;
        font-weight: bold;
        letter-spacing: 0.6px;
        border: none;
        background: rgba(180, 28, 59, 0.72);
        border-radius: 9px;
      }
    }
  }
}
.login-logo {
  position: absolute;
  width: 110px;
  height: 41px;
  left: 0px;
  top: 0px;
}

.forgetpass-back-to-login {
  color: $white;
  position: absolute;
  top: 15px;
  right: 15px;
}
.forgetpass-forget-password {
  color: $white;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.forgetpass-page-wrapper .OTP-inner:active {
  border: 1px solid #9e1d40 !important;
}
