@import 'theme/variables.scss';

.Login {
  height: 100vh;
  .v3-login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .v3-login-content {
      min-width: 400px;

      .error-msg {
        display: inline-block;
        padding: 10px 0;
        color: $color-v3-red-imperial;
      }
    }
  }

  .mt-30 {
    margin-top: 3rem;
  }
}
