@import 'theme/variables';

.lytics-dropdown {
  .title {
    color: var(--White, #FFF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .dropdown-arrow {
    svg {
      fill: $white;
      width: 13px;
height: 13px;
    }
  }
}
