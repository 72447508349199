.news-ticker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .channel-logo {
    position: absolute;
    top: -5000px;
    visibility: none;
  }

  .close-ticker-icon {
    svg {
      path:nth-of-type(1) {
        fill: transparent;
      }
      path:nth-of-type(2) {
        fill: #3e404b;
      }
      path:nth-of-type(3) {
        fill: white;
      }
    }
  }
}
