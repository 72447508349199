@import '../../../theme/variables.scss';
.player-controls-container {
  color: $white;
  width: 100%;
  display: block;
  background-color: $darkGray;
  min-height: 140px;
  max-height: 140px;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-top: 2rem;
  //bottom: -2.5rem;
  .ant-row {
    //padding: 4px !important;

    .ant-row {
      padding: 0px;
    }
  }

  .control-icon {
    font-size: 18px;
    padding: 0px 5px;
    color: $white;
    padding-top: 14px;
    :hover {
      cursor: pointer;
      opacity: 0.9;
    }
    .anticon {
      color: $white;
    }
  }
  .calendar-icon {
    font-size: 30px;
    margin-left: 20px;
  }
  .live-play-icon {
    margin: 0px;
    margin-bottom: 5px;
  }
  .live-play-control {
    margin-top: 1.2rem;
    text-align: center;
    cursor: pointer;
    .control-icon {
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-top: 3rem;
      // padding-top: 3rem;
    }
    color: $color-green;
  }
  // .ant-btn-text:focus,
  // .ant-btn-text:hover {
  //   background-color: $orange;
  // }
}
.volume-control-container {
  background: $white;
  .volume-control {
    height: 100px;
    padding: 10px 0px;
    .ant-slider-vertical {
      margin: auto;
    }
  }
}
.ant-popover-arrow {
  visibility: hidden;
}
.ant-popover-inner {
  border-radius: 0px !important;
}
.ant-popover-inner-content {
  padding: 0px 0px;
  border: none;
}
.date-time-container {
  height: 350px;
  width: 412px;
  border-radius: 0px;
  padding: 10px 40px;
  background: $secondary-background;
  h2 {
    color: $white;
  }
  .radio {
    display: block;
    height: 30px;
    line-height: 30px;
    color: $white;
  }
  .ant-picker-suffix {
    color: $white;
  }
  .action-buttons {
    margin-top: 15px;
  }
  .ant-form-item-control-input {
    min-height: 0%;
  }
}
.ant-picker-time-panel-column:after {
  display: none;
}

.ant-picker-input input {
  letter-spacing: 3px;
}

.player-speed {
  background-color: transparent !important;
  opacity: 0.5;
  user-select: none;
  color: lightgray;
  // font-size: 0.8rem;
  // width: 30px;
  font-weight: bold;
  opacity: 1 !important;
  &:hover {
    cursor: auto;
    color: lightgray;
    opacity: 1 !important;
  }
}
