@import 'theme/variables';
.createPass-page-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  //   background-color: $secondary-background;
  .createPass-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 582.57px;
    height: 370px;
    padding: 50px;
    // background-color: $color-primary-dark;
    .title {
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;
      span {
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 0.7px;
        color: $white;
      }
    }
    .text {
      // width: 100%;
      width: 75%;
      text-align: center;
      margin-top: 2rem;
      color: #d3d3d3;
      span {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.7px;
        line-height: 16px;
      }
    }
    .form-input-field {

    //   background: rgba(61, 1, 27, 0.15);
    //   border: 1px solid rgba(239, 35, 60, 0.43);
    //   border-radius: 4px;
    //     width: 556px !important;
    //     height: .9px !important;
    //     background: rgba(61, 1, 27, 0.15) !important;
    //     border: 1px solid #9e1d40 !important;
    //     border-radius: 4px;
    
    //     font-weight: 300;
    // font-size: 16px;
    // line-height: 19px;
    
    // color: #FFFFFF
    
    // opacity: 0.5;

      width: 556px;
      height: 52.9px;
      color: $white;
      border: none;
      padding: 15px;
      font-size: 15px;
      font-weight: 300;
      opacity: 0.5;
      letter-spacing: 1.1px;
      //   background-color: $secondary-background !important;
      background: rgba(61, 1, 27, 0.15);
      border: 2px solid #9e1d40;
      border-radius: 4px;
      margin-bottom: 1rem;
    }

    .createPass-forget-password {
      color: $white;
    }
    .createPass-button-div {
      //   width:100%;
      .createPass-form-button {
        margin-top: 30px;
        width: 160px;
height: 42px;
        color: #e7223c;
        font-weight: bold;
        letter-spacing: 0.6px;
        // box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.15);
        color: white;
        background: rgba(180, 28, 59, 0.72);
border-radius: 9px;

        // background: rgba(158, 29, 64, 0.49);
        border: 1px solid #b41c3b;
        // border-radius: 7px;
      }
    }
  }
}
.login-logo {
  position: absolute;
  width: 110px;
  height: 41px;
  left: 0px;
  top: 0px;
}
