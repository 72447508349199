@import 'theme/variables';

.switches {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  .svg {
    display: flex;
    justify-content: center;
    align-items: center;
    // font-size: 1rem;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 100%;
    cursor: pointer;

    &.active-svg {
      background-color: $color-v3-red-imperial;
    }
  }
}

.channel-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  .channel-wrapper {
    background-color: #313952;
    padding: 5px 0;
    width: 100%;
    // aspect-ratio: 1/1;
    cursor: pointer;
    color: #d3d3d3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;

    .channel-image {
      display: block;
      width: 80%;
      margin: 0 auto;
      aspect-ratio: 1/1;
      object-fit: contain;
    }

    .channel-name {
      margin: 0;
      text-align: center;
      color: #d3d3d3;
      font-weight: bolder;
      font-size: 12px;
    }
  }
}
