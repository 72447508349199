@import 'theme/variables';

.ims-main-menu {
  background: #000425 !important;
  .ant-menu-item {
    text-transform: uppercase;
    padding: 0px 10px !important;
    opacity: 1;
    background-color: transparent !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    a {
      color: $white;
      &:hover {
        color: #ef233c;
      }
    }
  }
  .ant-menu-item.active {
    font-weight: 700;
    a {
      color: #ef233c;
    }
  }
  .ant-menu-item .active {
    font-weight: 700;
  }
  .ant-menu-item.disable {
    display: none;
    background-color: transparent !important;
    cursor: auto;
    a {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.sub-menu {
  background: $secondary-background;
  display: flex;
  justify-content: center;
  .ant-menu {
    background: none;
  }
}
