@import 'theme/variables';

.ListView {
  .list-item {
    padding: 5px 0px;
    border-bottom: 1px solid white;
    .channel-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 2px;
      padding: 5px;
      cursor: pointer;
      &.active {
        background-color: $color-v3-grey-mid;
      }
      .col {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .channel-logo {
        width: 40px;
        height: 40px;
        // object-fit: contain;
        padding: 0px;
      }
    }
  }
}
