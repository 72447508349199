@import 'theme/variables.scss';

.job-table-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 10px;
  .bg-mid-grey .search-box-input {
    background-color: $color-v3-grey-mid !important;
  }
  .job-table-wrapper {
    flex-grow: 1;
    overflow: hidden;
  }
}
