@import 'theme/variables.scss';

.ClientLibrary {
  display: flex;
  flex-direction: column;
  height: 100%;

  .job-count-section {
    padding: 1rem 0px;
    span {
      font-size: 20px;
    }
  }
  .main-content-section {
    padding-bottom: 5px;
    flex-grow: 1;
    overflow: hidden;

    display: flex;
    gap: 1rem;
    .col {
      overflow: hidden;
      padding-bottom: 10px;
    }

    .col-1 {
      //   background-color: rgb(75, 72, 40);
      flex-grow: 1;

      .table-pagination-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;

        .v3-table-container {
          flex-grow: 1;
          overflow: hidden;
        }
      }
    }

    .col-2 {
      min-width: 450px;
      display: flex;
      flex-direction: column;
      &.d-none {
        display: none;
      }

      .preview-section-header {
        min-height: 30px;
      }

      .preview-section-body {
        flex-grow: 1;
        overflow: hidden;
      }
    }
  }
  .transcript-svg > path {
    fill: #00ab4b;
  }

  .rtl {
    line-height: 34px;
    text-align: right;
  }
}

.ant-client-library-main-tab {
  border-radius: 0px !important;   // or other value
}

.modal-wrapper{
  .ant-modal-content{
    border: 1px solid white;
  }
}
.share-Icon-New{
  display: flex;
  flex-direction: row-reverse;
}

.ant-list-item-meta:hover{
  background-color: #444444 !important;
  box-shadow: #303030 !important;
}

.ant-list-item:hover{
  background-color: #444444 !important;

  box-shadow: #303030 !important;

}
