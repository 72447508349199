@import 'theme/variables';
.screen-parent {
  // height: 83.7vh;
  // background-color: #2a324a;
  .refrehBtnClass {
    margin-right: 24px;
    font-size: 1.5rem;
  }
  .BN-wrapper {
    background-color: #2a324a;
    height: 100%;
    overflow: visible;

    .grey-box {
      height: 90%;
      margin-bottom: 5%;
      padding-bottom: 2%;
      background-color: #2a324a;
    }
    .heading-wrapper {
      height: 60px;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      margin-right: 5px;
    }
    .sub-heading {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        margin: 10px 0 0 10px;
        font-weight: 500;
        font-size: 18px;
        color: $color-white;
      }
    }
    .ticker-date {
      span {
        margin: 0 0 0 10px;
        color: $color-white;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .ticker-select {
      margin: 5px 0 5px 0;
      width: 100%;
      .ticker-select-container {
        display: flex;
        flex-direction: row;
        // flex-basis: 50%;
        // flex-grow: 1;
        justify-content: space-evenly;
      }
    }
    .channel-logo {
      margin-top: 8px;
    }
    .ticker-canvas {
      user-select: none;
      display: flex;
      width: 100%;
      // height: 230px;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;
      padding-left: 15px;
      canvas {
        width: 250px;
        height: 280px;
        margin: 5px 0 0 15px;
        //   border: 1px solid #3e404b;
      }
    }
    .ticker-footer {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .window-close-icon {
      z-index: 9999;
      svg {
        path:nth-of-type(1) {
          fill: #ef233c;
        }
        path:nth-of-type(2) {
          fill: #3e404b;
        }
        path:nth-of-type(3) {
          fill: #ef233c;
        }
      }
    }
  }
  .segment-container .segment-container-body.secondary-bg {
    padding: 0px;
    overflow: auto;
    overflow-x: hidden;
    height: 79.1vh;
  }
}
.segment-container .segment-container-body.secondary-bg {
  padding: 0px;
  // overflow: auto;
  overflow-x: hidden;
  // height: 75vh;
}
