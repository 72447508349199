@import 'theme/variables.scss';

.LibraryHeader {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .search-box-container {
    min-width: 450px;
  }
}
