@import '../../../theme/variables.scss';

.channel-window-container {
  min-height: 636px;
  max-height: 480px;
  display: flex;
  position: relative;
  margin-top: -10px;
  margin-left: 30px;
  background-color: $color-light-grey;
  div {
    h1 {
      color: white;
      font-size: x-large;
    }
  }
  .window-close-icon {
    font-size: 18px;
    top: -10px;
    right: -10px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    svg {
      path:nth-of-type(1) {
        fill: #EF233C;
      }
      path:nth-of-type(2) {
        fill: #3e404b;
      }
      path:nth-of-type(3) {
        fill: #EF233C;
      }
    }
  }
  .player-container,
  .fullscreen {
    position: absolute;
    top: 0px;
    left: 0px;
    min-height: 300px;
    height: 100%;
    max-height: 500px;
    margin: auto auto;
    width: 100%;
    .no-live-media{
      display: flex;
        position: absolute;
        top: 50%;
        width: 100%;
        justify-content: center;
    
    }
  }
  video {
    object-fit: fill;
  }
}
