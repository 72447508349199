.web-wrapper {
  height: 75vh;
  overflow: auto;
  .web-model-wrapper {
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title-channels {
    padding: 2rem;
    background-color: #3e404b;
    color: #ffffff;
  }
}
