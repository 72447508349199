@import 'theme/variables.scss';



.player-controls-wrapper-news-board {
  position: absolute;
  top: 70%;
  width: 100%;
  padding: 5px;
  // display: none;

  .player-time-wrapper {
    display: flex;
    align-items: center;

    .time {
      font-family: unispace;
      letter-spacing: 1.5px;
      padding: 5px;
      font-size: 12px;
    }

    .start-time {
      // margin-right: auto;
    }
    .end-time {
      // color: #ef233c;
      display: none;

    }

    .control-icon {
      font-size: 20px;
      padding: 0px 5px 0px 0px;
      color: $white;
      font-weight: bold;
      padding: 0;

      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
      .anticon {
        color: $white;
      }
    }

    .ant-slider {
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover .ant-slider-track {
        background-color: #121a34 !important;
      }
      .ant-slider-rail {
        height: 5px;
        background-color: #313952 !important;
        display: none;
      }
      .ant-slider-track {
        height: 5px;
        background-color: red !important;
      }
      .ant-slider-handle {
        background-color: white !important;
        border: 2px solid white !important;
        width: 10px;
        height: 10px;
        margin-top: -1px;
      }
    }
  }
  .pin-controls {
    display: flex;
  }
  .slider {
    .time-line--container {
      position: relative;
      display: flex;
      background: #4b5f9c;
      .timeline {
        position: relative;
        .pin {
          position: absolute;
          right: -5px;
          cursor: pointer;
          display: inline-block;
          transform: translate(-50%);
          height: 42px;
          margin-top: -30px;
          z-index: 1;
          display: none;
        }
      }
    }
    .ant-slider {
      margin: 0px;
      padding: 0px;
    }
    .ant-slider-rail {
      height: 3px;
      background-color: #313952;
    }
    .ant-slider-track {
      height: 3px;
      background-color: red;
    }
    .ant-slider-handle {
      background-color: white;
      border: 2px solid white !important;
      width: 10px;
      height: 10px;
      margin-top: -3px;
    }
  }

  .control-icons-row {
    margin-top: 10px;
    // border: 1px solid lightblue;
    display: flex;
    // grid-template-columns: 1fr 1fr 1fr;
    .col {
      // border: 1px solid lightgreen;
      flex: 1;
    }

    .col-1 {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .pin {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        background-color: $color-ligt-grey;
        padding: 5px 14px;
        margin-right: 5px;
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }
    }

    .col-2 {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }

    .col-3 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 7px;

      .btn {
        display: flex;
        cursor: pointer;
        border: none;
        color: white;
        width: 78.01px;
        justify-content: center;
        text-align: center;
        align-items: center;
        height: 31.12px;
        background: #455177;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }
    }

    .control-icon {
      font-size: 20px;
      padding: 0px 5px 0px 0px;
      color: $white;
      font-weight: bold;
      padding: 0;

      :hover {
        cursor: pointer;
        opacity: 0.9;
      }
      .anticon {
        color: $white;
      }
    }
    .big-icon {
      font-size: 30px;
      object-fit: contain;
      display: flex;
      align-items: center;
    }
    .ant-slider {
      padding: 0px;
    }
    .ant-slider-rail {
      height: 5px;
      background-color: #313952;
    }
    .ant-slider-track {
      height: 5px;
      background-color: #121a34;
    }
    .ant-slider-handle {
      background-color: #ef233c;
      border: 2px solid #ef233c !important;
      margin-top: -2px;
    }
  }
}


.player-controls-wrapper-news-board{
  display: none;
}

.channel-controls-wrapper:hover{
  .player-controls-wrapper-news-board {
    display: block !important;
  }
}
.ant-tabs-top{
  //border-radius: 5%;
}
.video-player-wrapper video{
  border-radius:5% ;
}