.segment-analysis-reviewer-wrapper {
  height: 70vh;
  overflow: auto;
  .card-detail-body {
    padding-left: 5px;
    .list-container {
      display: flex;
    }
  }
  .card-container-primary .ant-card-head {
    font-size: 18px;
  }
  .card-container-primary .ant-card-body {
    padding-top: 0;
  }
}
