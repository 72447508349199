@import 'theme/variables.scss';

.clip-wrapper {
  .thumbnail-image {
    width: 100%;
    aspect-ratio: 16/9;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: $white;
  }

  .d-flex {
    display: flex;
    align-items: center;

    .dot {
      width: 5px;
      height: 5px;
      background-color: white;
      border-radius: 100%;
    }
  }

  .skeleton {
    width: 100%;
    border-radius: 5px;
  }
}

.no-selection {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: white;
  margin-top: 85%;
}
