@import 'theme/variables.scss';

.MultiSelect {
  width: 100%;
  .ant-select-selector {
    width: 100%;
    background-color: $color-v3-grey-mid !important;
    padding: 10px 8px !important;
    border: 1px solid;
    border-color: transparent !important;
    outline: none;
    width: 100%;

    &:focus-within {
      border: 1px solid white !important;
    }
  }
}

.v3-option {
  background-color: transparent !important;
  .select-item {
    display: flex;
    align-items: center;
    gap: 1rem;

    .check-mark-wrapper {
      border: 1px solid white;
      padding: 8px;
      display: flex;
      position: relative;
    }
  }
  .ant-select-item-option-state {
    display: none;
  }

  .check-mark {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    display: none;
  }
}

.ant-select-item-option-selected {
  .check-mark {
    display: inline-block;
  }
}

.rc-virtual-list-holder-inner .v3-option div:hover {
  background-color: transparent !important;
}

.ant-select-arrow {
  svg {
    fill: white;
  }
}
