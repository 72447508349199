.awareness-wrapper {
  height: 90vh;
  position: relative;
  // margin-left: -20px;
  // margin-right: -20px;
  overflow: hidden;
  // display: flex;
  // flex-direction: row;
  // ::-webkit-scrollbar {
  //   display: none;
  // }
  .abc {
    video {
      object-fit: fill;
    }
  }
  .channel-drawer-button-main {
    .channel-drawer-button-left {
      position: absolute;
      top: 0;
      left: 0;
    }
    .channel-drawer-button-right {
      position: absolute;
      top: 0;
      right: 0;
    }
    .channel-drawer-button-wrapper {
      height: 89vh;
      width: 71px;
      // background-color: #131c3a;
      background-color: #303030; 


      // text-align: center;
      .menu-icon {
        font-size: '2rem';
      }
      .right-drawer-buttons {
        padding-top: 12px;
        height: 53px;
        width: 71px;
        font-size: '2rem';
        cursor: pointer;
        text-align: start;
        .draw-text-style {
          font-weight: 700;
          font-size: 20px;
        }
      }
    }
    .drawer-button-wrapper {
      height: 89vh;
      width: 71px;
      // background-color: #131c3a;
      background-color: #303030; 


      // text-align: center;
      .menu-icon {
        font-size: '2rem';
      }
      .right-drawer-buttons {
        // padding-top: 16px;
        height: 53px;
        width: 71px;
        font-size: '2rem';
        cursor: pointer;
        text-align: start;
        .draw-text-style {
          font-weight: 1000;
          font-size: 13px;
          margin-top: 2px;
          // display: inline-block;
        }
      }
    }
  }
  .right-drawer-close {
    width: 100%;
    margin-top: 15px;
    // enable ml and mr for old version
    // margin-left: 100px;
    // margin-right: 75px;
    transition: margin-top 0.5s ease-in-out, margin-left 0.5s ease-in-out, width 0.5s ease-in-out,
      margin-right 0.5s ease-in-out;
  }

  .right-drawer-open {
    //enable in old vertion 
    // width: calc(100% - 400px);
    width:100%;
    margin-top: 15px;
    margin-left: 23px;
    // margin-right: calc(200px + 75px);
    transition: margin-top 0.5s ease-in-out, margin-left 0.5s ease-in-out, width 0.5s ease-in-out,
      margin-right 0.5s ease-in-out;
  }
  .left-drawer-open {
    width: 100%;
    margin-top: 15px;
    // margin-right: 90px;
    // margin-left: 30px;
    margin-left: -40px;

    transition: margin-top 0.5s ease-in-out, margin-left 0.5s ease-in-out, width 0.5s ease-in-out,
      margin-right 0.5s ease-in-out;
  }

  .channel-wrapper {
    width: 400px;
    height: 89vh;
    // overflow-y: auto;
    overflow-x: hidden;
    transition: width 0.5s ease-in-out;
    z-index: 1;
    margin-right: -1rem;

    
  }
  .left-drawer-wrapper-breakingnewsT {
    width: 400px;
    // height: 78vh;
    position: absolute;
    right: 71px;
    top: 0;
    z-index: 2;
    overflow: auto;
    transition: width 0.5s ease-in-out;
    background-color: blueviolet;
    // height: 84.3vh;
    background-color: #3e404b;
    box-shadow: -5px 0 0 0 rgb(0 0 0 / 0%), -10px 0px 20px 0 rgb(0 0 0 / 19%);
  }

  .left-drawer-wrapper-breakingnews {
    width: 400px;
    // height: 78vh;
    position: absolute;
    right: 71px;
    top: 0;
    z-index: 2;
    overflow: auto;
    transition: width 0.5s ease-in-out;
    background-color: blueviolet;
    // height: 84.3vh;
    background-color: #3e404b;
    box-shadow: -5px 0 0 0 rgb(0 0 0 / 0%), -10px 0px 20px 0 rgb(0 0 0 / 19%);
  }
  .left-drawer-wrapper-ticker {
    width: 400px;
    // height: calc(100% - 0px);
    position: absolute;
    right: 71px;
    top: 0;
    z-index: 2;
    // overflow: auto;
    transition: width 0.5s ease-in-out;
    box-shadow: -5px 0 0 0 rgb(0 0 0 / 0%), -10px 0px 20px 0 rgb(0 0 0 / 19%);

    .segment-container .segment-container-header {
      padding: 0 0;
    }
  }
  .left-drawer-wrapper-content {
    width: 400px;
    height: 80vh;
    position: absolute;
    right: 71px;
    top: 0;
    z-index: 2;
    overflow: auto;
    transition: width 0.5s ease-in-out;
    box-shadow: -5px 0 0 0 rgb(0 0 0 / 0%), -10px 0px 20px 0 rgb(0 0 0 / 19%);
  }
  .left-drawer-wrapper-breakingnews {
    top: 53px;
  }
  .left-drawer-wrapper-content {
    top: 106px;
  }
  .inactive {
    width: 0px;
  }
  .segment-container .segment-container-header {
    height: 53px;
  }
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
    background-color: #3e404b;
  }
  label {
    font-size: 11px !important;
  }
}

// .segment-container .segment-container-header {
//   width: 53px;
// }
// .ant-drawer-content-wrapper {
//   margin-top: 7%;
// }
.ant-drawer-mask {
  opacity: 0 !important;
  // width: 300px;
  // display: none;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 300px;
}
// ::-webkit-scrollbar {
//   display: none;
// }
.ant-select-selector {
  border-radius: 5px !important;
}

.awareness-tabs-wrapper {
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: rgba(242, 106, 50, 1) !important;
    }
  }
  .ant-tabs-tabpane,
  .ant-tabs-tabpane-active {
    background-color: #2d2f3a !important;
  }
  .table-container .ant-table .ant-table-thead tr th {
    // background-color: #5a5c69;
    // border-radius: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
}
