@import 'theme/variables';

.MediaManager {
  padding: 10px;
  height: 100%;
  // overflow: hidden;
  .h-100 {
    height: 100%;
    padding: 10px;
    overflow: scroll;
  }
}

.grid {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  .col {
    overflow: hidden;
    border-radius: 6px;
  }
}
