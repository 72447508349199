@import 'theme/variables';

.clipper-wrapper {
  margin-top: 1.3rem;
  .actus-wrapper {
    position: relative;
    width: 100%;
    height: 379px;
    background-color: $color-light-grey;

    .actus-player-container,
    .fullscreen {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      margin: auto auto;
      width: 100%;
    }
  }

  .center-divider {
    border-right: 1px solid rgba($color: $color-grey, $alpha: 0.5);
  }

  .thumbnails-wrapper {
    height: 35.8rem;
    // max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .sub-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      font-weight: 500;
      font-size: 18px;
      color: $color-white;
    }
    padding-bottom: 16px;
  }

  .export-output {
    width: 100%;
    margin-left: 10px;
  }
  .process-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 100%;
    min-height: 35.8rem;

    .ant-select-selection-item {
      background:  #131C3A !important;
      border-color: #3e404b !important;
      color: #f2f2f2;
    }

    .clipper-footer {
      width: 100%;
      display: flex;
      justify-content: center;
      // margin-top: 2.7rem;
    }
    .export-footer {
      // margin-top: 14.8rem;
      // margin-bottom: 1rem;
      margin-left: 0.3rem;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
  }
}
