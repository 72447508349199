@import 'theme/variables.scss';
.V3Table {
  height: 100%;
  padding-top: 0;
  //   overflow: hidden;

  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-container {
    height: 100%;
    // overflow: hidden;
  }

  .ant-table-wrapper {
    .ant-spin.ant-spin-spinning {
      max-height: 100%;
      background-color: $color-v3-black;
    }
  }
  .ant-table {
    .ant-table-container {
      display: flex;
      flex-direction: column;

      .ant-table-header {
        overflow: unset !important;
        border-bottom: 1px solid white;
        border-top: 1px solid white;
        tr {
          th {
            padding: 8px;
            font-family: 'Roboto';
          }
          & td:nth-child(1) {
            border-left: 2px solid transparent;
          }
        }
      }

      .ant-table-body {
        flex-grow: 1;
        overflow: scroll;
        table {
          // height: 100%;
        }

        .ant-table-tbody {
          height: 100%;
          max-height: 100%;
          overflow: hidden;
          tr {
            height: auto;
            cursor: pointer;

            &:nth-child(2n) {
              background-color: $color-v3-grey-dark;
            }
            &.ant-table-row:hover > td {
              background-color: inherit;
            }
            td {
              border: none;
              padding: 8px;
              text-transform: capitalize;
            }

            & td:nth-child(1) {
              border-left: 2px solid transparent;
            }

            &.active-row {
              & td:nth-child(1) {
                border-left: 2px solid red;
              }
            }

            // styles for empty place holder //
            &.ant-table-placeholder > td {
              background-color: $color-v3-black;
            }

            &.ant-table-placeholder:hover > td {
              background-color: $color-v3-black;
            }
          }
        }
      }
    }
    background: $color-v3-black;
    border-radius: 0;
    .ant-table-thead {
      tr {
        th {
          //   padding: 30px;
          background: $color-v3-black;
          border: none;
        }
      }
    }
  }
}
