@import 'theme/variables.scss';

.GeneralTabContent {
  // height: 100%;
  // background-color: yellow;
  padding-top: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  .content-body {
    flex-grow: 1;
    overflow: hidden;
    .clip-wrapper {
      height: auto;
      max-height: 100%;
      overflow-x: auto;
      .thumbnail-image {
        width: 100%;
        aspect-ratio: 16/9;
      }

      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: $white;
      }

      .d-flex {
        display: flex;
        align-items: center;

        .dot {
          width: 5px;
          height: 5px;
          background-color: white;
          border-radius: 100%;
        }
      }

      .skeleton {
        width: 100%;
        border-radius: 5px;
      }
    }
  }
  .content-footer {
    border-top: 1px solid $color-v3-grey-light;
  }
}

.no-selection {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: white;
  margin-top: 85%;
}

.ticker-heading-date {
  margin-right: 10px;
  font-weight: 400;
}

.ticker-heading {
 font-size: $medium-font-size;
 font-weight: 500;
}

.b-r {
  border-radius: 8px !important;
  // padding: 14px !important;
}

.ant-form-item textarea.ant-input {
  background: $color-v3-grey-mid !important;
  border: none;
  border-radius: 8px !important;
  // padding: 14px;
}

.divider {
  border-bottom: 1px solid $color-v3-grey-light;
}


