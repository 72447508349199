@import 'theme/variables.scss';

.video-player-container-v3 {
  //   display: flex;
  //   justify-content: center;
  .job-player-container {
    width: 98%;
    padding: 0px 10px;
    margin: 0 auto;
    position: relative;
    .window-close-icon {
      font-size: 20px;
      top: 0px;
      right: 0px;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      svg {
        path:nth-of-type(1) {
          fill: #ef233c;
        }
        path:nth-of-type(2) {
          fill: #3e404b;
        }
        path:nth-of-type(3) {
          fill: #ef233c;
        }
      }
    }
  }

  .medium {
    background-color: $color-v3-grey-dark;
    .speed-btn {
      background-color: $color-v3-grey-dark;
    }
  }
}
