.job-card-wrapper {
  margin-bottom: 5px;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    align-items: center;
    background-color: #121a34;

    .ant-collapse-expand-icon {
      .ant-collapse-arrow {
        margin-right: 5px;
      }
      svg {
        width: 20px;
        height: auto;
        fill: white;
      }
    }
  }
  .job-card-header {
    background-color: #121a34;
    transition: background-color 0.3s, visibility 0s;
    cursor: pointer;

    .job-title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;

      letter-spacing: 0.4px;

      color: #ffffff;
    }

    .job-channel-name {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 8px;
      line-height: 16px;
      /* identical to box height, or 200% */

      letter-spacing: 0.4px;

      color: #ffffff;
    }

    .job-topic {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      /* or 133% */

      letter-spacing: 0.4px;
    }

    .job-anchor {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 16px;
      /* identical to box height, or 160% */

      letter-spacing: 0.4px;
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header,
    .job-card-header {
      background-color: #313952 !important;
    }
  }
  .segment-statement-content-wrapper {
    height: 100%;
    color: white;
    ::-webkit-scrollbar-button {
      /* background-color: #3e404b; */
      background-size: 10px 10px;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #d3d3d3;
      height: 16px;
      width: 1em;
      -webkit-box-shadow: none;
    }
    ::-webkit-scrollbar-button {
      background-color: #d3d3d3;
    }
    ::-webkit-scrollbar {
      background-color: #d3d3d3;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #313952;
    }
    ::-webkit-scrollbar-track {
      background-color: #d3d3d3 !important;
    }
    .bg-selected {
      background-color: yellow !important;
      color: black;
    }
    .abc {
      flex-direction: column;
      margin-bottom: 20px;
      color: white;
      .speaker {
        width: 100%;
        padding: 0px 5px;
      }
      .tag {
        width: 100%;
        padding: 0px 10px;
      }
    }

    .ant-card.card-container-secondary.grey.round {
      background-color: transparent;

      border-radius: 0px;
    }
  }
  .round {
    border-radius: 0px !important;
  }

  .light {
    background-color: #121a34 !important;
  }

  .ant-collapse-content-box {
    background-color: #1c274b;
    .content-wrapper {
      background-color: #121a34;
      max-height: 400px;
      overflow: scroll;

      .segment-statement-content-wrapper {
        height: 100%;
        color: white;
        ::-webkit-scrollbar-button {
          /* background-color: #3e404b; */
          background-size: 10px 10px;
          background-repeat: no-repeat;
          background-position: center center;
          background-color: #d3d3d3;
          height: 16px;
          width: 1em;
          -webkit-box-shadow: none;
        }
        ::-webkit-scrollbar-button {
          background-color: #d3d3d3;
        }
        ::-webkit-scrollbar {
          background-color: #d3d3d3;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #313952;
        }
        ::-webkit-scrollbar-track {
          background-color: #d3d3d3 !important;
        }
        .bg-selected {
          background-color: yellow !important;
          color: black;
        }
        .abc {
          flex-direction: column;
          margin-bottom: 20px;
          color: white;
          .speaker {
            width: 100%;
            padding: 0px 5px;
          }
          .tag {
            width: 100%;
            padding: 0px 10px;
          }
        }

        .ant-card.card-container-secondary.grey.round {
          background-color: transparent;

          border-radius: 0px;
        }
      }
      .round {
        border-radius: 0px !important;
      }

      .light {
        background-color: #121a34 !important;
      }
    }

    .print-web {
      padding: 8px 12px;
      .job-heading {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        /* or 139% */

        display: flex;
        align-items: center;
        letter-spacing: 0.44px;
      }

      .job-publisher {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 11px;
        line-height: 16px;
        /* or 145% */

        display: flex;
        align-items: center;
        letter-spacing: 0.4px;
      }
    }

    // padding: 12px;
  }
  .content-social {
    background-color: #121a34;

    .program-user {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      /* identical to box height, or 150% */

      letter-spacing: 0.375069px;
    }
    .user {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 15px;
      /* identical to box height, or 150% */

      letter-spacing: 0.375069px;
    }
  }

  .content-ticker {
    .ant-image {
      width: 100%;
    }
  }
  .dot {
    width: 1px;
    aspect-ratio: 1/1;
    background-color: white;
    border-radius: 50%;
  }
  .dot-bg {
    display: inline-block;
    background-color: white;
    width: 20px;
    aspect-ratio: 1/1;
    border-radius: 50%;
  }

  .social-heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    display: inline-block;
    padding: 5px;

    letter-spacing: 0.375069px;
  }

  .rtl {
    direction: rtl;
    line-height: 30px !important;
  }

  .ltr {
    direction: ltr;
    line-height: 18px;
  }
}

.custom-loading {
  height: 100%;
  min-height: auto;
}
