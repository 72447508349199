@import 'theme/variables.scss';

.Checkbox {
  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: white;
  }
  .ant-checkbox-inner {
    border: 1px solid white;
    background-color: transparent;
    outline: none;
  }

  .ant-checkbox-checked:after {
    border-color: white;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: white;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: white;
  }
}
