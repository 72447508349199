.progress-container {
  width: 46px;
  height: 34px;
  background-color: #5a5c69;
  border-radius: 3px;
  display: flex;
  align-items: flex-end;
  &:not(:last-child) {
    margin-right: 10px;
  }
  .progress {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    border-radius: 3px;
    .word {
      font-family: 'Roboto';
      font-size: 11px;
      color: #ffffff;
      letter-spacing: 0.4px;
      line-height: 16px;
      z-index: 2;
    }
    .count {
      font-size: 10px;
    }
    .progress-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.7;
      border-radius: 3px;
      width: 100%;
    }
  }
}
