@import 'theme/variables.scss';

.SingleSelect {
  width: 100%;
  .ant-select-selector {
    width: 100%;
    background-color: $color-v3-grey-mid !important;
    padding: 10px 8px !important;
    height: auto !important;
    border: 1px solid;
    border-color: transparent !important;
    outline: none;
    width: 100%;
    border-radius: 8px !important;
    &:focus-within {
      border: 1px solid white !important;
    }
  }
}

.bg-black {
  .ant-select-selector {
    background-color: $color-v3-grey-dark !important;
  }
}

.v3-option-single {
  background-color: transparent !important;
  .ant-select-item-option-state {
    display: none;
  }
}

.rc-virtual-list-holder-inner .v3-option-single div:hover {
  background-color: transparent !important;
}

.ant-select-arrow {
  svg {
    fill: white;
  }
}
