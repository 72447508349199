
@import 'theme/variables';

.add-more-wrapper {
  cursor: pointer;

  span.text {
    color: $color-grey;
    padding-left: 5px;
  }
}