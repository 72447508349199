.summary-wrapper{
  .title{
    color:white;
  }
}

.summary-tags-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-tag {
    margin-top: 5px;
  }
}
