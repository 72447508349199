@import '../../theme/variables.scss';
.admin-panel-style {
  .form-row {
    // disable input type = number arrows globally
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .heading-col {
      color: $color-pink;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 14px;
      letter-spacing: 0.7px;
      margin-bottom: 20px;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color:#EF233C !important;
  }
    .form-column {
      margin-right: 39px;
      .input-label {
        color: $text-primary;
        font-size: $small-font-size;
        letter-spacing: 0.5px;
      }
      .form-select {
        font-size: $small-font-size;
        min-width: 100%;
        margin-top: 5px;

        .ant-select-selection-item {
          color: $white;
        }
      }
      .form-multi-select {
        font-size: $small-font-size;
        min-width: 100%;
        margin-top: 5px;
        .ant-select-selection-item {
          color: $white;
        }
      }
      .form-input {
        padding: 3px;
        font-size: $small-font-size;
        box-sizing: border-box;
        min-width: 100%;
      }

      .form-input-table {
        min-width: auto;
      }
      .form-dynamic-field {
        display: flex;
        flex-direction: row;
        align-items: center;

        .form-dynamic-field-plus-icon {
          font-size: 20px;
          margin: 8px;
          margin-top: 12px;
          color: $color-pink;
        }
      }
      .threshold-title {
        color: $text-primary;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 24px;
        letter-spacing: 0.7px;
        line-height: 14px;
      }
    }
    .form-column-table {
      margin-right: 0px;
      .theme-table {
        width: 100%;
        tr {
          width: 100%;
          display: flex;
          justify-content: space-between;
          td.subTheme {
            width: 50%;
            display: flex;

            padding: 10px;
            border: 1px solid rgba(93, 87, 87, 0.527);
            border-collapse: collapse;
            .topic2-container {
              display: flex;
              align-items: flex-start;
              width: 100%;
            }
          }
          td.topic3 {
            flex-direction: column;
          }
        }
      }
    }
    .form-picture {
      margin-right: 25px;
      text-align: center;
    }

    .user-picture {
      text-align: left;
      width: auto;
    }
  }
  
  .form-title {
    .title {
      color: $white;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 1.2px;
    }
    .save-btn {
      margin: -20px 0px 25px -10px;
      color: $color-pink;
      background: linear-gradient(
        257.51deg,
        rgba(252, 95, 69, 0.1) 1.48%,
        rgba(254, 153, 96, 0.1) 59.07%
      );
      border: 1px solid $color-pink;
      height: 45px;
      width: 70px;
      border-radius: 14px;
      &:hover {
        color: $color-pink;
        background: linear-gradient(
          257.51deg,
          rgba(252, 95, 69, 0.4) 1.48%,
          rgba(254, 153, 96, 0.4) 59.07%
        );
        border-color: #EF233C;
      }
    }
  }
.rc-virtual-list-holder-inner div:hover{
  background-color:  #EF233C;
}
  .bordered-row {
    border-top: 1px solid #d3d3d3;
    // border-bottom: 1px solid #d3d3d3;
    padding: 40px;
    margin: 20px -40px 0 !important;

    .heading-col {
      color: $color-pink;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 14px;
      letter-spacing: 0.7px;
      margin-bottom: 20px;
    }

    .sub-heading-col {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.7px;
      color: $white;
      margin: 10px 0px 20px 0;
    }

    .toggle-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 16px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.7px;
      color: #cacaca;
      border: 1px solid #adadad;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
    }

    .toggle-button-active {
      border: 1px solid $color-pink;
      color: #ffffff !important;
      background: $color-pink;
    }

    .right-border {
      border-right: 1px solid #d3d3d3;
    }

    .padding-right {
      padding-right: 20px;
    }

    .padding-40 {
      padding: 40px !important;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: $color-pink !important;
    }
  }

  .no-padding {
    padding: 0;
  }
  .no-margin-top {
    margin-top: 0 !important;
  }
  .margin-top {
    margin-top: 10px;
  }

  .checkbox {
    // width: 23px;
    // height: 23px;
  }

  .ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox {
        .ant-checkbox-inner {
          border: none;
        }
      }
    }
    .checkbox-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: $white;
    }

    .checkbox-text-active {
      color: $color-pink;
    }
    // .ant-checkbox {
    //   width: 23px;
    //   height: 23px;
    //   .ant-checkbox-inner {
    //     text-align: center;
    //     width: inherit;
    //     height: inherit;
    //   }
    // }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $color-pink;
      border-color: $color-pink;
    }
    .ant-checkbox-checked::after {
      outline: none;
      border: none;
    }
  }
}
