.card-wrapper {
  height: 100%;
  padding-top: 10px;

  .ant-card,
  .card-container-primary,
  .medium,
  .square {
    height: 100%;
  }

  .overflow {
    .ant-card-body {
      height: calc(100% - 48px);
      overflow-y: auto;
    }

    .ant-card-extra {
      margin-right: 10px;
      padding-top: 20px;
      padding-bottom: 0;
    }
  }

  .segment-analysis-wrapper-clipper {
    height: 100%;

    .tooltip-span {
      display: none;
      background-color: white;
      color: black;
      padding: 5px;
    }

    &:hover .tooltip-span {
      display: block;
      position: fixed;
      overflow: hidden;
    }
  }
}

.program-info-body-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 355px !important;
  overflow: auto;

  .ant-select-selection-item {
    background: #72747e !important;
    border-color: #72747e !important;
    color: #f2f2f2;
  }

  .card-container-secondary {
    margin-top: 15px;
  }
}