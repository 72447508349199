@import 'theme/variables';
.search {
  overflow: hidden;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 80vh;
    // min-height: calc(100vh - 150px);
    box-sizing: border-box;
    .search-bar {
      background: #232943;
      box-shadow: 0px 11.9429px 24.9714px rgba(0, 0, 0, 0.02);
      border-radius: 25.5px;
      width: 684px;
      border: none;
      height: 51px;
      margin-bottom: 10px;
    }
  }
}
.hashtag-list-container {
  background-color: #373a59;
  padding: 10px 20px;
  border-radius: 0px 0px 12px 12px;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 47px;
  height: auto;
  max-height: 200px;
  overflow: auto;
  border-top: 2px solid #5c5f6ead;
  padding-top: 20px;
  margin-top: -13px;
  margin-right: 2px;
  margin-left: 2px;
  .hashtag-list {
    list-style: none;
    padding: 0;
    background-color: #373a59;
    .hashtag {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.44px;
      color: #d3d3d3;
      cursor: pointer;
      line-height: 1.5;
      padding: 1px 0;
      text-align: left;
      padding-left: 15px;
      background-color: #373a59;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.hide {
  display: none;
}
.hashtag-collections {
  position: relative;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex-wrap: wrap;
  height: 15vh;
  width: 50%;
  margin-top: 20px;
  max-height: 50%;
  overflow: auto;
  margin-bottom: 5rem;
  &::-webkit-scrollbar {
    display: none;
  }
  .hash-tag {
    height: 42px;
    display: flex;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border: none !important;
    background: rgba(34, 36, 82, 0.55);
    border-radius: 21px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: white;
    letter-spacing: 0.7px;
    cursor: pointer;
  }
  .ant-spin-dot-item {
    background: rgba(180, 28, 59);
  }
}
