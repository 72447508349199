@import 'theme/variables.scss';

.SocialButtons {
  .social-buttons-wrapper {
    display: flex;
    gap: 12px;
    background-color: $color-v3-grey-mid;
    border-radius: 8px;
    padding: 12px;
    .icon {
      width: 30px;
      height: 30px;
      opacity: 0.5;
      cursor: pointer;

      &.active {
        opacity: 1;
      }

      &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.2;
      }
    }
  }
}
