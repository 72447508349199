@import 'theme/variables.scss';

.player-controls-wrapper {
  // width: 100%;
  // background-color: lightblue;
  padding: 10px 0;

  .player-time-wrapper {
    display: flex;
    align-items: center;

    .time {
      font-family: unispace;
      letter-spacing: 1.5px;
      padding: 5px;
      font-size: 1rem;
    }

    .start-time {
      margin-right: auto;
    }
    .end-time {
      color: #ef233c;
    }
  }
  .pin-controls {
    display: flex;
  }
  .slider {
    .time-line--container {
      position: relative;
      display: flex;
      background: #4B5F9C;
      .timeline {
        position: relative;
        .pin {
          position: absolute;
          right: -5px;
          cursor: pointer;
          display: inline-block;
          transform: translate(-50%);
          height: 42px;
          margin-top: -30px;
          z-index: 1;
        }
      }
    }
    .ant-slider {
      margin: 10px 0;
    }
    .ant-slider-rail {
      height: 17px;
      background-color: #313952;
    }
    .ant-slider-track {
      height: 17px;
      background-color: #121a34;
    }
    .ant-slider-handle {
      background-color: #ef233c;
      border: 2px solid #ef233c !important;
      width: 17px;
      height: 17px;
      margin-top: 0px;
    }
  }

  .control-icons-row {
    margin-top: 10px;
    // border: 1px solid lightblue;
    display: flex;
    // grid-template-columns: 1fr 1fr 1fr;
    .col {
      // border: 1px solid lightgreen;
      flex: 1;
    }

    .col-1 {
      display: flex;
      align-items: center;
      .pin {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        background-color: $color-ligt-grey;
        padding: 5px 14px;
        margin-right: 5px;
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }
    }

    .col-2 {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }

    .col-3 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 7px;

      .btn {
        display: flex;
        cursor: pointer;
        border: none;
        color: white;
        width: 78.01px;
        justify-content: center;
        text-align: center;
        align-items: center;
        height: 31.12px;
        background: #455177;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      // .screen-btn,
      // .ticker-btn {
      //   cursor: not-allowed;
      // }
    }

    .control-icon {
      font-size: 20px;
      padding: 0px 5px 0px 0px;
      color: $white;
      // padding-top: 14px;
      font-weight: bold;
      padding: 0;

      :hover {
        cursor: pointer;
        opacity: 0.9;
      }
      .anticon {
        color: $white;
      }
    }
    .big-icon {
      font-size: 30px;
      object-fit: contain;
      display: flex;
      align-items: center;
    }
    .ant-slider {
      padding: 0px;
    }
    .ant-slider-rail {
      height: 12px;
      background-color: #313952;
    }
    .ant-slider-track {
      height: 12px;
      background-color: #121a34;
    }
    .ant-slider-handle {
      background-color: #ef233c;
      border: 2px solid #ef233c !important;
      width: 12px;
      height: 12px;
      margin-top: 0px;
    }
  }
}
