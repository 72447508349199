@import 'theme/variables.scss';

.lens-share-wrapper {
  background-color: #121a34;
  color: $white;
  border: 1px solid #1d2642;
  border-radius: 5px;
  padding: 10px;

  .lens-input {
    background-color: #1d2642 !important;

    border-radius: 5px;
    padding: 12px 8px;
  }

  .lens-textarea {
    background-color: #1d2642 !important;
    border-radius: 5px;
    height: 120px;
    resize: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .lens-input,
  .lens-textarea {
    font-size: 1rem;
    color: $white;
    &:placeholder {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.386312px;

      /* Text Gray */

      color: #d3d3d3;

      opacity: 0.4;
    }
  }

  .mic-icon {
    width: 70px;
    height: 70px;
    cursor: pointer;
  }

  .company-container {
    background: #1d2642;
    border-radius: 10px;
    padding: 10px;
    .company-select {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 5px 0px;
    }
    .company-select:hover {
      background-color: #373a56;
      border-radius: 10px;
      box-shadow: 0 0 10px #373a56;
      font-weight: 400;
    }
    .ant-list-split {
      align-items: center;
    }
    .ant-list-split:hover {
      cursor: pointer;
    }

    .ant-list-split .ant-list-item {
      border-bottom: none !important;
    }
    .ant-list-split .ant-list-item:hover {
      background-color: #373a56;
      border-radius: 10px;
      box-shadow: 0 0 10px #373a56;
      font-weight: 400;
    }
    .company-select:hover {
      cursor: pointer;
    }
    .checkBox {
      position: relative;
      background: #8b1e41;
      border-radius: 5px;
      padding: 0 5px 0 5px;
    }

    .sb-avatar__text {
      background-color: transparent !important;
      color: #cc0545 !important;
      border: 3px solid #8b1e41;
      font-family: 'Roboto';
      font-size: 18px !important;
      font-weight: 800 !important;
      line-height: 16px;
      letter-spacing: 0.4px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radi .ant-list-split .ant-list-item {
        border-bottom: none;
      }
    }
  }
}
