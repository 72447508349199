.qc-wrapper {
  width: 100%;
  // height: 5rem;
  height: 100%;
  background-color: #3e404b;
  padding: 10px;
  display: flex;
  flex-direction: row;
  //min-height: 100px;
  img {
    padding-bottom: 5px;
  }
  .program-info-label-wrapper {
    width: 100%;
    height: 100%;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    .label {
      padding: 0px 0px 5px 0px;
    }
    .left-program-info {
      float: left;
      padding: 10px;
    }

    .right-program-info {
      float: right;
      margin: 10px;
    }
  }
}
@media screen and (min-width: 786px) {
  /* big landscape tablets, laptops, and desktops */
  .qc-wrapper {
    width: 100%;
    // height: 3.3rem;
    background-color: #2a324a;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    //min-height: 100px;
    img {
      padding-bottom: 5px;
    }
    .program-info-label-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .prgram-info-data {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 1281px) {
  .qc-wrapper {
    width: 100%;
    height: 5.5rem;
    background-color: #3e404b;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    //min-height: 100px;
    img {
      padding-bottom: 5px;
    }
    .program-info-label-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .prgram-info-data {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
