@import 'theme/variables';

.main-search-fields-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 0px !important;
  .search-field {
    flex-grow: 1;
    // padding: 0 10px;
    // margin-top: 0px !important;
    position: relative;
    .bottom-border {
      position: absolute;
      bottom: 1px;
      left: 10px;
      right: 10px;
      height: 1px;
      background: #d3d3d3;
      z-index: 3;
    }

    .ant-input-affix-wrapper {
      &:hover {
        z-index: 0;
      }
      //background: $secondary-background;
      // border-bottom: 1px solid white !important;
      //border-bottom: 2px solid $border-bottom;
      border-radius: 12px;
      .ant-input-prefix {
        padding: 10px;
        font-size: 20px;
        color: $color-pink;
        margin-left: 10px;
      }
      .ant-input {
        height: 40px;
        margin-left: 30px;
        // font-weight: bold;
        background-color: #3e404b;
        caret-color: $color-pink;
        &:focus {
          outline: none !important;
        }
      }
    }
  }

  .round {
    border: 1px solid $white;
    border-radius: 6px;

    .bottom-border {
      display: none;
    }

    .ant-input-affix-wrapper {
      .ant-input-prefix {
        padding: 0px;
        font-size: 20px;
        color: $color-pink;
        margin-left: 10px;
      }

      .ant-input {
        padding: 0.5rem;
      }
    }
  }
}
