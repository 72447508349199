@import 'theme/variables.scss';

.MapComponent {
  height: 100%;
  padding-bottom: 10px;

  .province-floating-up {
    animation: floatUp 0.5s ease-in-out forwards;
  }

  @keyframes floatUp {
    from {
      transform: translateY(0);
      z-index: 1;
    }
    to {
      transform: translateY(-100%);
      z-index: 2;
    }
  }

  .map-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .province-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.5s ease-in-out;
  }

  .province-floating-up .province-overlay {
    opacity: 1;
  }
  /* MapComponent.css */
  .map-container {
    position: relative;
  }

  .map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 4px;
  }
}
