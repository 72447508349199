.Model {
  background-color: #2a324a;
  height: 229px;
  width: 308px;
  .modal-heading {
    text-align: center;
    .modal-close-icon {
      font-size: 20px;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      margin-top: -10px;
      margin-right: -10px;
      svg {
        path:nth-of-type(1) {
          fill: #ef233c;
        }
        path:nth-of-type(2) {
          fill: #3e404b;
        }
        path:nth-of-type(3) {
          fill: #ef233c;
        }
      }
    }
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2rem;

    .upload-button {
      margin-left: 2rem;
      // margin-right: 2rem;
      width: 50%;

      .btn {
        background-color: #d3d3d3;
        color: #32333c;
        border: none;
        border-radius: 4px;
        width: 129px;
        height: 56px;
        font-family: Roboto;
        font-weight: 800;
        font-size: 18px;
      }
      .btn:hover {
        // background-color: #d3d3d3;
        border: none;
        color: #32333c;
      }
    }
    .file-label {
      // margin-left: 0.5rem;
      width: 50%;
      h1 {
        color: #d3d3d3;
        font-family: Roboto;
        margin: 0;
      }
    }
  }
  .Modal-footer-button {
    margin-top: 1rem;
    // padding-bottom: 1rem;
    margin-left: 2rem;
  }
}
