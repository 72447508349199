.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1.5s ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1.5s ease-out;
}

.slide-enter {
  opacity: 0;
  transform: translateX(-100%);
  filter: brightness(0%) grayscale(100%);
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s, transform 0.5s;
  filter: brightness(100%) grayscale(0%);
}

.slide-exit {
  opacity: 1;
  filter: brightness(100%) grayscale(0%);
}

.slide-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s, transform 0.5s;
  filter: brightness(0%) grayscale(100%);
}

.header-icon{
  .ant-collapse-header{
    color: white !important;
  }
}