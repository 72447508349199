@import 'theme/variables.scss';
.timeline-container {
  display: flex;
  background-color: #5f98ee;
  width: 100%;
  position: relative;
  .timeline {
    position: relative;
    cursor: pointer;

    .react-contextmenu {
      position: fixed;
      width: 200px;
      left: 0;
      top: calc(100% + 10px);
      border-radius: 4px;
      background-color: #fff;
      padding: 10px 0;
      z-index: 99;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

      .react-contextmenu--item:hover:not(.react-contextmenu-item--disabled) {
        background-color: #f1f1f1;
      }
      .context_menu--item {
        font-size: 14px;
        display: block;
        text-decoration: none;

        cursor: pointer;
        user-select: none;
        transition: 0.2s;
        position: relative;
        margin-bottom: 2px;
        font-weight: 500;
        display: flex;
        align-items: center;
        outline: none;

        &:hover {
          background: #cccccc;
        }
        span {
          display: inline-block;
          padding: 10px 15px;
          width: 100%;
        }
      }
    }
  }
  .timeline-marker {
    position: absolute;
    bottom: -8px;
    right: -4px;
    transform: translate(50%, 13%);
  }

  .dummy-marker {
    position: absolute;
    bottom: -8px;
    left: -4px;
  }
}

.fa.fa-trash {
  color: red;
  display: inline-block;
  margin-right: 5px;
}
