@import 'theme/variables.scss';

.process-buttons-container {
  .process-button {
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
  }

  .ant-btn {
    background-color: $color-v3-red-imperial;
    color: $white;
    border-color: $color-v3-red-imperial;
    border-radius: 2px;
    width: 100%;
    min-width: 105px;

    &:hover,
    &:focus {
      // border-color: $color-ligt-grey;
      //   border: none;
    }
  }

  .ant-btn[disabled] {
    background-color: $color-v3-grey-mid;
    border-color: $color-v3-grey-mid;
    color: $color-v3-grey-light;
  }

  .ant-btn[disabled]:hover {
    background-color: $color-v3-grey-mid;
    border-color: $color-v3-grey-mid;
    color: $color-v3-grey-light;
  }
}
