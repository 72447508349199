@import 'theme/variables';
.login-wrapper-2 {
  // background: linear-gradient(328.09deg, #9f1d40 -4.17%, #010741 47.02%);
  display: flex;
  flex-direction: column;
  height: 100vh;

  align-items: center;
}
.login-wrapper {
  // background: linear-gradient(328.09deg, #9f1d40 -4.17%, #010741 47.02%);
  width: 100%;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-main-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .mid-line {
      width: 1px;
      background-color: #ffffff;
      height: 510px;
    }
    .logo-area {
      margin-top: 130px;
      display: flex;
      flex-direction: column;
      .main-title {
        color: $color-pink;
        font-size: 100px;
      }
      .sub-title {
        color: $color-pink;
        font-size: 24px;
        text-align: left;
      }
    }
    .input-div {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .error-msg {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #e6e6e6;
        padding: 8px 0px;
      }

      .form-input-field {
        height: 52.9px;
        background: $color-input-bg !important;
        color: $white;
        width: 556px;
        margin-top: 20px;
        font-weight: 300;
        letter-spacing: 1.1px;
        border: 0.5px solid #642a31 !important;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
      }
      .form-input-field-selected {
        height: 52.9px;
        background: rgba(61, 1, 27, 0.15) !important;
        color: $white;
        width: 556px;
        margin-top: 20px;
        font-weight: 300;
        letter-spacing: 1.1px;
        border: 1px solid #ef233c;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
      }

      .ant-input .form-input-field:default {
        background-color: #010741 !important;
      }
      .login-form-format {
        margin-top: 30px;
        align-self: start;
        .login-form-checkbox {
          color: $white;
          font-size: $regular-font-size;
          flex: 1;
        }
        .stay_sign {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.44px;
          color: #e0e0e0;
          padding-left: 6.67px;
          padding-right: 11.33px;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #9e1d40;
          border-color: #9e1d40;
        }
        .forgotPass-btn {
          width: 184.99px;
          height: 29.81px;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.44px;
          color: #ff465d;
          cursor: pointer;
          padding-left: 1rem;
        }
      }
      .login-button-div {
        width: 100%;
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        align-items: center;
      }
      .create_account {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #ff465d;
      }
      .forgotPass-btn {
        width: 184.99px;
        height: 29.81px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.44px;
        color: #ff465d;
      }
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
@media screen and (max-width: 1600px) {
  .login-main-grid {
    height: 100vh !important;
  }
}
