@import '../../theme/variables';

.home-container {
  // // background-image: url('../../theme/images/home/HomeBackground.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $body-background;
  height: 90vh;
  scroll-behavior: smooth;
  .recharts-legend-wrapper {
    top: 5px;
  }

  .spotlight {
    @media only screen and (min-width: $minResolution) {
      height: $carouselHeightMin + 115px;
    }

    @media only screen and (min-width: $midResolution) {
      height: $carouselHeightMid + 115px;
    }

    @media only screen and (min-width: $maxResolution) {
      height: $carouselHeightMax + 115px;
    }

    @media (min-width: 360px) and (max-width: 480px) {
      height: 260px; // added during mobile optimization
    }
    .ant-spin {
      i {
        background-color: #43487a;
      }
    }

    overflow: hidden;
    height: 500px;
  }

  .menuRow {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 58px;
    z-index: 100;
    height: 46px;
    background-color: $color-blue;
  }

  .categorySection {
    padding-left: 13px;
    margin-top: 32px;
    @media (min-width: 360px) and (max-width: 480px) {
      margin-top: 0; // added during mobile optimization
      // width: 850px;
    }
  }
}
.ant-picker-suffix{
  color:white;
}

.dashboard-container {
  overflow-x: hidden;
  .graph-filters-container {
    height: 60px;
    display: flex;
    align-items: center;
    align-self: center;
    margin: 0 30px;
  }
  .ant-col {
    padding-right: 0px !important;
  }
  .dashboard-col {
    padding-left: 8px !important;
  }
  .graph-heading {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    padding: 15px 30px 0px;
  }
  .graph-container {
    height: 450px;
    position: relative;
    .recharts-tooltip-wrapper {
      color: #000;
      position: relative;
    }
    .graph-download-button {
      position: absolute;
      right: 10px;
      bottom: 20px;
    }
  }
  .graph-container-copy {
    height: 1200px;
    width: 1200px;
  }
  .electronic-media {
    min-height: 550px;
    background: $secondary-background;
    border-radius: 1rem;
  }
  .significant-sub-themes {
    height: 550px;
    background: $secondary-background;
    border-radius: 1rem;
  }
  .trend-analysis {
    height: 550px;
    background: $secondary-background;
    border-radius: 1rem;
  }
  .negative-sentiments {
    height: 387px;
    background: $secondary-background;
    border-radius: 1rem;
  }
  .mini-stats-container {
    padding: 8px 20px;
    .mini-stats {
      height: 180px;
      background: $secondary-background;
      border-radius: 20px;
    }
    .query-header {
      padding-top: 10px;
      color: $color-white;
      text-align: left;
      margin-left: 20px;
    }
    .query-count {
      background-color: #f3ec78;
      background-image: linear-gradient(257.51deg, #EF233C 1.48%, #EF233C 59.07%);
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      font-size: 30px;
      text-align: center;
      margin-top: 40px;
    }
    .query-inputs-div {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .right-labels {
        margin-left: 18%;
      }
      .left-labels {
        margin-left: 20px;
      }
      .query-inputs {
        width: 100px;
        margin-left: 20px;
        margin-top: 10px;
      }
      .range-picker {
        max-width: 80%;
        margin-left: 15%;
        margin-top: 10px;
      }
    }
  }
}
#selectFirstOption {
  background-color: inherit;
}
#selectFirstOption:hover {
  background-color: inherit;
}
#hrSelect {
  width: 70px;
  margin-left: 0px;
}
.themesSelect {
  // color: white !important;
  background-color: yellow !important;
}
#subThemeTopOption {
  background-color: inherit;
}
#subThemeTopOption:hover {
  background-color: inherit;
}
.spin {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  margin-top: 150px;
  border-radius: 4px;
}
.ant-select-item-option-state {
  // background: linear-gradient(257.51deg, #EF233C 1.48%, #EF233C 59.07%);
  height: 19px;
  padding: 1px;
  margin-right: -6px;
  margin-left: 5px;
  color:#EF233C !important;
  border-radius: 10%;
  opacity: 0.9;
}
#bar {
  height: 30px !important;
  padding: 20px !important;
}
#select {
  display: flexbox;
}
.ant-picker-input input {
  letter-spacing: 0px;
}
.date_classes {
  font-size: 12px;
  margin-right: -10px;
  opacity: '0.8';
}

.graph_fonts {
  margin-top: 10px;
  margin-bottom: 3px;
  padding-left: 12px;
  margin-bottom: 3px;
}

@media only screen and (min-width: 2400px) {
  .dashboard-container .graph-heading {
    display: flex;
    justify-content: space-between;
    font-size: 23px;
    padding: 15px 30px 0px;
  }
  .date_classes {
    font-size: 14px;
    margin-right: -10px;
    opacity: '0.8';
  }
  .graph_fonts {
    font-size: 23px;
    margin-top: 10px;
    margin-bottom: 3px;
    padding-left: 10px;
  }
  .dashboard-container .graph-filters-container {
    height: 60px;
    display: flex;
    align-items: center;
    align-self: center;
    margin: 0 30px;
    justify-content: space-around;
  }

  .main-content-wrapper {
    padding: 0px 0px;
    margin: 30px;
  }
  .dashboard-container .electronic-media {
    min-height: 550px;
    background: #3e404b;
    border-radius: 1rem;
  }
  .dashboard-container .mini-stats-container .mini-stats {
    height: 240px;
    background: #3e404b;
    border-radius: 20px;
  }
  .dashboard-container .mini-stats-container .query-header {
    padding-top: 40px;
    color: #ffffff;
    text-align: left;
    margin-left: 20px;
  }
  .negative-sentiments {
    height: 504px !important;
    background: $secondary-background;
    border-radius: 1rem;
  }
}
