.qc-edit-wrapper {
  padding: 0 0 0 15px;
  .site-card-wrapper {
    margin-top: 0rem;
    .Speaker-title {
      padding-top: 1rem;
    }
    .speaker-dropdown-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
  .qc-edit-header-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    .right-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 26px;
      .ims-button-wrapper {
        // padding-left: 20px;
      }
    }
  }
}
.qc-video-wrapper {
  display: flex;
  height: 40%;
  flex-direction: column;
  .qc-video-statment-spacing {
    margin-top: 10px;
  }
  .diff {
    margin-top: 40px;
  }
  .middle-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 1281px) {
  .qc-video-wrapper {
    display: flex;
    height: 40%;
    flex-direction: column;
    .qc-video-statment-spacing {
      margin-top: 10px;
    }
    .diff {
      margin-top: 40px;
    }
  }
}
.speaker-wrapper {
  display: flexbox;
  // border: 2px solid white;
  width: 100%;
  // height: 20vh;
  justify-content: space-between;
  background-color: #3e404b;
  margin-bottom: 30px;
}

h2 {
  text-align: center;
  color: white;
  margin-bottom: 15px;
  font-size: 20px;
}

.select {
  display: block;
}

.bg-selected {
  background-color: yellow;
}
.red {
  color: red;
}
.green {
  color: green;
}
.blue {
  color: blue;
}
