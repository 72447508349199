.export-wrapper {
  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 200px;
  }
  .upload .ant-upload.ant-upload-select-picture-card > .ant-upload {
    width: 200px;
  }
  .upload-text {
    display: none;
  }
}
