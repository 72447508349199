@import 'theme/variables';
.guest-analysis-wrapper {
  max-height: 80vh;
  overflow: auto;
}
.guest-analysis-body-wrapper {
  .table-container {
    .ant-table {
      .ant-table-thead {
        tr {
          th {
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            border-bottom: 2px solid $border-primary;
          }
        }
      }
      tr {
        &:hover {
          td {
            background: none;
          }
        }
        td {
          text-align: center;
        }
      }
    }

    .icons-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .anticon {
        font-size: 18px;
        cursor: pointer;
      }

      .anticon.anticon-minus-square {
        color: $color-red;
      }
      .anticon.anticon-plus-square {
        color: $color-green;
      }
    }
  }
}
