@import 'theme/variables.scss';

.player-controls-wrapper-v3 {
  // width: 100%;
  // background-color: lightblue;
  padding: 10px;

  .slider {
    .time-line--container {
      position: relative;
      display: flex;
      background: #4b5f9c;
      .timeline {
        position: relative;
        .pin {
          position: absolute;
          right: -5px;
          cursor: pointer;
          display: inline-block;
          transform: translate(-50%);
          height: 42px;
          margin-top: -35px;
          z-index: 1;
        }
      }
    }
    .ant-slider {
      margin: 10px 0;
    }
    .ant-slider-rail {
      height: 17px;
      // background-color: #313952;
    }
    .ant-slider-track {
      height: 17px;
      background-color: #121a34;
    }
    .ant-slider-handle {
      background-color: #ef233c;
      border: 2px solid #ef233c !important;
      width: 17px;
      height: 17px;
      margin-top: 0px;
    }
  }

  .pin {
    cursor: pointer;
  }

  .player-time-wrapper {
    display: flex;
    align-items: center;

    .time {
      font-family: unispace;
      letter-spacing: 1.5px;
      padding: 0px;
      font-size: 14px;
      font-weight: 400;
    }

    .live-button {
      margin-right: auto;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1px 5px;
      cursor: pointer;
    }

    .live-button-active {
      background-color: $color-v3-red-imperial;
    }
  }

  .action-buttons {
    font-size: 12px;
    font-weight: 400;
    width: 110px;
    &.active {
      background-color: $color-v3-red-imperial;
      border-color: $color-v3-red-imperial;
    }
  }

  .ant-slider {
    &:hover .ant-slider-rail {
      background-color: $color-v3-grey-mid;
    }
    &:hover .ant-slider-track {
      background-color: #121a34;
    }
    padding: 0px;
  }
  .ant-slider-rail {
    height: 12px;
    background-color: $color-v3-grey-mid;
  }
  .ant-slider-track {
    height: 12px;
    background-color: #121a34;
  }
  .ant-slider-handle {
    background-color: $color-v3-red-imperial;
    border: 2px solid $color-v3-red-imperial !important;
    width: 12px;
    height: 12px;
    margin-top: 0px;
  }

  .control-icon {
    font-size: 20px;
    padding: 0px 5px 0px 0px;
    color: $white;
    // padding-top: 14px;
    font-weight: bold;
    padding: 0;

    :hover {
      cursor: pointer;
      opacity: 0.9;
    }
    .anticon {
      color: $white;
    }
  }
  .big-icon {
    font-size: 30px;
    object-fit: contain;
    display: flex;
    align-items: center;
    &:focus {
      outline: none;
    }
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: 10px;

    .play-icon {
      &:focus {
        outline: none;
      }
    }
  }
}
