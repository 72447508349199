.screen-shot-wrapper {
  display: flex;
  // gap: 1rem;
  flex-wrap: wrap;
  & > * {
    flex-basis: 33%;
  }
  position: relative;
  .channel-logo {
    position: absolute;
    top: -5000px;
    visibility: none;
  }
  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    // aspect-ratio: 16/9;

    position: relative;
    img {
      width: 100%;
      // object-fit: conver;
      height: 100%;
      //   aspect-ratio: 16/9;
    }

    .icon-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: none;

      .image-remove-icon {
        font-size: 1.3rem;
        cursor: pointer;
        svg {
          path:nth-of-type(1) {
            fill: transparent;
          }
          path:nth-of-type(2) {
            fill: #3e404b;
          }
          path:nth-of-type(3) {
            fill: white;
          }
        }
      }
    }

    &:hover .icon-container {
      display: flex;
    }
  }
}
