// .title-wrapper{
//     display: flex;
//     justify-content: 'space-between';
//     align-items: center;
//     .title{
//         flex: 0 0 90%;
//         max-width: 90%;
//         overflow: hidden;
//     }
//     .title-icon{
//         flex: 0 0 10%;
//         max-width: 10%;
//         display: flex;
//         margin-left: 1px;
//         justify-content: flex-end;
//     }
// }
// .title-wrapper {
//   .title {
//     margin-left: 3.5%;
//   }
// }
.ant-select-clear {
  color: white;
  // left: 1.5%;
  right: 30px;
}
.ant-select-clear:hover {
  color: white;
}
.rc-virtual-list-holder-inner div:hover {
  background-color: #8b1e41;
}
.title-icon {
  margin-left: 5px;
}
