@import 'theme/variables.scss';

.pdfReports {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    margin: auto;
    padding-top: 0;
    color: white;
    .reports_heading{
        margin-top: 1rem;
        text-align: left;
        color: black;
    }
   .pdf-reports-btn{

    text-align: end;
   }
   .pdf-reports-btn button{
    background: transparent;
    // border: 1px solid white;
    padding: 10px;
   }
    .main-content-section{
        // margin-top: 2rem;
        background-color: white;
        padding: 0 45px;
        // text-align: center;
    }
    .v3-table-container{
        margin-top: 2rem;
    }
    .custom-ant-table {
        width: 100%;
        padding-bottom: 15px;
        // border: 0.5px solid black;
        border-collapse: collapse !important;
      }
      
      .custom-ant-table .ant-table-thead > tr > th,
      .custom-ant-table .ant-table-tbody > tr > td {
        color: black !important;
        text-align: center;
        border: 1px solid;
        border-collapse: collapse !important;

      }
    
      .custom-ant-table .ant-table-thead > tr > th {
        background-color: white;
      }
      
      .header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        text-align: center;
        color: black;
      }
      
      .header-text {
        font-size: 18px;
        font-weight: 800;
        line-height: 20px;
        margin-bottom: 2px;
      }
      
      // .header-text-location {
      //   // color: black;
      // }
      
      p {
        color: black;
        // font-size: 14px;
        font-weight: 600;
      }
      
      h2 {
        text-align: center;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
        color: black;
        font-weight: 500;
      }
      
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        // color: black !important;
        text-align: center;
      }
      .img{
        height: 110px;
        width: 90px;
        padding-top: 10px;
        margin-right: 20px;
      }
      .footer {
        text-align: center;
        // margin-top: 10px;
        padding: 10px 0 15px 0;
        background-color: white;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        color: #555;
      }
      .pdf-footer {
        text-align: center;
        margin-top: 20px;
        padding: 10px 0;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        color: #555;
      }
    
}

// .header {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 20px 0 0 0;
//     font-family: Arial, Helvetica, sans-serif;
//     text-align: center;
//   }
//   .header-text {
//     font-size: 18px;
//     font-weight: 700;
//     /* text-align: center; */
//   }


// .App {
//     font-family: sans-serif;
//     text-align: center;
//   }

//   table {
//     border-collapse: collapse;
//     width: 100%;
//   }

//   td,
//   th {
//     border: 1px solid #dddddd;
//     text-align: center;
//     padding: 6px 20px;
//   }
//   tr th {
//     border: 1px solid #dddddd;
//     text-align: center;
//     padding: 6px 20px;
//     background-color: #ad1e23;
//     color: #fff;
//   }

//   tr:hover {
//     background-color: #dddddd;
//     cursor: pointer;
//   }

//   .content td,
//   .content th {
//     border-top: 1px solid transparent;
//     padding: 2px 10px 2px 15px;
//   }